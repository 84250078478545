$step-content-width: 50px;
$border-of-snake-width: 1px;
$border-of-snake: #{$border-of-snake-width} solid #1E3A8A;
$border-of-snake-radius: 12px;

$horizontal-between-items: #{$border-of-snake-width} solid #1E3A8A;
$horizontal-between-rows: #{$border-of-snake-width} solid #1E3A8A;
$vertical-row-edge: #{$border-of-snake-width} solid #1E3A8A;
$horizontal-row-edge: #{$border-of-snake-width} solid #1E3A8A;

.steps-list-looped {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: $border-of-snake-radius;
    .step-wrapper {
        text-align: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: stretch;
        flex-basis: 33%;
        margin-bottom: -$border-of-snake-width;
        &::before, &::after {
            content: '';
            display: block;
            flex-basis: 100%;
        }
        &::before {
            height: 50%;
            border-bottom: $horizontal-between-items;
        }
        &::after {
            align-self: self-end;
            margin-top: auto;
            margin-bottom: -$border-of-snake-width;
            height: calc(50% + #{$border-of-snake-width * 2});
            border-top: $horizontal-between-items;
        }
        .step-content {
            flex-shrink: 0;
            flex-basis: $step-content-width;
            margin: 0 auto;
            align-self: center;
        }
        border-bottom: $horizontal-between-rows;

        &:first-child {
            &::before {
                border-bottom-color: transparent;
                border-top-color: transparent;
                border-left-color: transparent;
            }
        }
        &:last-child {
            &::after {
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-right-color: transparent;
            }
        }
    }
}

$number-of-cols: 1, 2, 2, 4;
$breakpoints-media: "only screen and (max-width : 399px)",
"only screen and (min-width : 400px) and (max-width : 749px)",
"only screen  and (min-width : 750px) and (max-width: 1199px)",
"only screen  and (min-width : 1200px)";
$bp: zip($number-of-cols, $breakpoints-media);
@each $breakpoint-cols, $breakpoint-media in $bp {
    @media #{$breakpoint-media} {
        .step-wrapper {
            flex-basis: calc(100% / $breakpoint-cols) !important;

            &:nth-child(#{$breakpoint-cols}n) {
                &::after {
                    margin-right: -$border-of-snake-radius;
                    border-bottom: $horizontal-row-edge;
                    border-right: $vertical-row-edge;
                    border-top-right-radius: $border-of-snake-radius;
                    border-bottom-right-radius: $border-of-snake-radius;
                }
            }
            &:nth-child(#{$breakpoint-cols}n+1) {
                &::before {
                    margin-left: -$border-of-snake-radius;
                    border-top: $horizontal-row-edge;
                    border-left: $vertical-row-edge;
                    border-top-left-radius: $border-of-snake-radius;
                    border-bottom-left-radius: $border-of-snake-radius;
                }
            }
        }
        $imax: $breakpoint-cols + 1;
        @for $i from 1 through $imax {

            /* Last line */
            $butNot: "#{$breakpoint-cols}n + #{$breakpoint-cols}";
            .step-wrapper:nth-last-child( #{$i} ):nth-child( #{$butNot} ) ~ .step-wrapper {
                border-bottom-color: transparent;
            }
        }
        @for $i from 1 through $breakpoint-cols {
            /* First line (ex. 4 in row, but there is only 3) */
            $butNot: "-n + #{$imax - $i}";
            .step-wrapper:nth-child(#{$i}):nth-last-child( #{$butNot} ) {
                border-bottom-color: transparent;
            }
        }
    }
}

.step-number {
    color: #444;
    width: 40px;
    font-size: 8px;
    height: 40px;
    margin: 55px 15px;
}

.step-number-box {
    min-width: 300px;
    font-size: 13px;
    min-height: 90px;
    margin: 1rem 0 1rem 0;
    text-align: center;
}


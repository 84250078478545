.header {
    background-color: #93c5fd;
    font-weight: bold;
    text-align: center;
    border-bottom: 2px solid #000;
}

.section {
    background-color: #f2f2f2;
    margin-top: 16px;
    border: 2px solid rgb(75,85,99)
}

.section-content {
    padding: 16px;
}

.main-logo {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.container {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensure children span the full width */
    border: 2px solid #000;
}

.fileInput {
    display: 'none',
}

.header {
    background-color: #385c94;
    font-weight: bold;
    color: white;
    text-align: center;
    border-bottom: 2px solid #000;
    flex: 0 0 auto; /* Allow the header to span its content */
    margin: 0;
    padding: 0;
    width: 100%; /* Extending the width slightly */
    box-sizing: border-box;
}

.a {
    color: blue;
    text-decoration: underline;
}

.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.floating-button:hover {
    background-color: #0056b3;
}
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import '../node_modules/@syncfusion/ej2-react-richtexteditor/styles/material.css';
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
/* @import "primereact/resources/themes/mdc-light-indigo/theme.css"; */
@import "btTheme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

.e-attr {
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    font-size: 18px;
}

.e-grid .e-row.redText .e-rowcell {
    color: red;
}

.infocop {
    background: rgb(128 128 128 / 0.25);
    font-size: 12px;
    align-content: center;
}

.infocop_footer {
    background: rgb(128 128 128 / 0.25);
    font-size: 12px;
    align-content: center;
    position: relative;
    bottom: 0;
}

.chart-container {
    width: 100vw;
    height: 100vh;
    padding: 16px;
}

.input_field {
    width: 230px;
    height: 56px;
}

.phone-input {
    margin-top: 16px;
    padding-top: 1px;
    padding-bottom: 5px;
    width: 100%;
    text-align: center;
}

    .phone-input:focus {
        border: none;
        outline: none;
    }

    .phone-input::placeholder {
        text-align: left;
    }

    .phone-input::-ms-input-placeholder {
        text-align: left;
    }

    .phone-input::-webkit-input-placeholder {
        text-align: left;
    }

    .phone-input:-moz-placeholder {
        text-align: left;
    }

    .phone-input::-moz-placeholder {
        text-align: left;
    }

.loading {
    font-weight: bold;
    display: inline-block;
    font-family: monospace;
    font-size: 30px;
    clip-path: inset(0 3ch 0 0);
    animation: l 1.5s steps(4) infinite;
}

.PersonAnalysisChartContainer {
    width: 100%;
    height: 100%;
    border-left: 1px solid black;
}

.label-extended label.e-float-text.e-label-top {
    white-space: pre-line;
    top: -12px !important;
}

.e-checkbox-custom .e-label {
    display: none !important;
}

th.MuiTableCell-root.MuiTableCell-head {
    border: 1px solid rgba(224, 224, 224, 1);
}

.customTable_toolbar {
    padding-left: 0px !important;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}

.e-grid .e-row.red .e-rowcell {
    color: #cd5c5c;
    font-weight: bold;
}

.e-grid .e-row.orange .e-rowcell {
    color: orange;
    font-weight: bold;
}

.custom-edit-box {
    border: 1px solid black;
    padding: 0 4px;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 7px 0 7px !important;
}

.e-tab .e-tab-header .e-indicator {
    background: #495057;
}

.custom-header-default {
    color: black;
    padding: 0 18px;
}

.custom-header-processing {
    background: #0d6efd;
    color: black;
    padding: 0 18px;
}

.custom-header-done {
    background: #198754;
    color: black;
    padding: 0 18px;
}

.custom-header-issue {
    background: #dc3545;
    color: black;
    padding: 0 18px;
}

.rectangle-default {
    width: 30px;
    height: 15px;
    border: 1px solid black;
    color: black;
    padding: 5px, 5px, 5px, 5px;
}

.rectangle-processing {
    background: #0d6efd;
    width: 30px;
    height: 15px;
    border: 1px solid black;
    color: black;
    padding: 5px, 5px, 5px, 5px;
}

.rectangle-done {
    background: #198754;
    width: 30px;
    height: 15px;
    border: 1px solid black;
    color: black;
    padding: 5px, 5px, 5px, 5px;
}

.rectangle-issue {
    background: #dc3545;
    width: 30px;
    height: 15px;
    border: 1px solid black;
    color: black;
    padding: 5px, 5px, 5px, 5px;
}

/*.e-sidebar-context {
    overflow-x: unset;
}

.e-grid .e-gridheader {
    position: -webkit-sticky;
    position: sticky;
    top: 56px;
    font: bold;
    z-index: 1;
} */